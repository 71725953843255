@import "/styles/variables.scss";
.Navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 15;
  font-size: 0px;
  background-color: #002855;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, .16);

  .navLink,
  .navSubLink {
    color: #000000;
    cursor: pointer;
    list-style: none;
    background-color: transparent;
  }

  .logoContainer {
    display: inline-block;

    .logo {
      width: auto;
    }

    .logoSubText {
      display: block;
    }

  }

  a {
    color: #000000;
    text-decoration: none;
  }
}

@media screen and (min-width: 0px){

  .Navigation {

    .logoContainer {
      margin: 15px;

      .logo {
        height: 30px;
      }

    }

    .menuButton {
      display: block;
      float: right;
      color: #FFFFFF;
      font-size: 0px;
    }

    .menuButton svg {
      margin: 12px 15px;
      font-size: 36px;
    }

    .menuButton.active svg {
      margin: 12px 20px;
    }

    .dropdownArrow {
      float: right;
      padding: 0px;
    }

    .dropdownArrow svg {
      font-size: 18px;
      z-index: 15;
    }

    nav {
      display: none;
    }

    nav.active {
      display: block;
      position: absolute;
      top: 60px;
      height: 100vh;
      width: 100%;
      background-color: #FFFFFF;
      border-top: 1px solid rgba(112, 115, 114, .25);
    }

    .navLink {
      display: block;
      position: relative;
    }

    .navLink a {
      display: block;
      font-weight: bold;
      font-size: 18px;
      padding: 8px 15px;
    }

    .navSubLink {
      display: block;
      font-size: 15px;
      padding: 8px 15px;
    }

    .navLink:active,
    .navLink.active {
      background-color: #F2F2F2;
    }

    .navLink:active a,
    .navLink.active a {
      color: #000000;
    }

    .dropdown {
      display: none;
    }

    .dropdown.active {
      display: block;
      text-align: left;
    }
    
  }

}

@media screen and (min-width: 500px){

  .Navigation {

    .logoContainer {
      margin: 17px 20px;

      .logo {
        height: 36px;
      }

      .logoSubText {
        display: block;
        color: #FFFFFF;
        margin: 2px auto 0px;
        font-size: 12px;
      }

    }

    .menuButton {
      display: block;
      float: right;
      font-size: 0px;
    }

    .menuButton svg {
      margin: 27px 20px;
      font-size: 36px;
    }

    .menuButton.active svg {
      margin: 27px 25px;
    }

    .dropdownArrow {
      float: none;
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }

    .dropdownArrow svg {
      font-size: 30px;
    }

    nav {
      display: none;
    }

    nav.active {
      display: block;
      position: absolute;
      top: 90px;
      width: 100%;
      height: auto;
      padding-bottom: 20px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, .16);
      border-top: 1px solid rgba(112, 115, 114, .25);
    }

    .navLink {
      display: block;
      position: relative;
    }

    .navLink a {
      display: block;
      font-size: 25px;
      padding: 10px 20px;
    }

    .navSubLink {
      display: block;
      font-size: 22px;
      padding: 10px 20px;
    }
    
    .navLink:active,
    .navLink.active {
      background-color: #F2F2F2;
    }

    .navLink:active a,
    .navLink.active a {
      color: #000000;
    }

    .dropdown {
      display: none;
    }

    .dropdown.active {
      display: block;
      text-align: left;
    }
    
  }

}

@media screen and (min-width: 950px){

  .Navigation {

    .logoContainer {
      margin: 15px 20px;

      .logo {
        height: 40px;
      }

      .logoSubText {
        margin: 3px auto 0px;
        font-size: 14px;
      }
    }

    .menuButton {
      display: none;
    }

    .externalMenu {
      float: right;
      margin-right: 5px;
    }

    .externalMenu .navSubLink {
      padding: 1px 15px;
      margin: 34px 0px 33px;
      font-size: 16px;
      color: #FFFFFF;
      display: inline-block;
      border-right: 1px solid #FFFFFF;
    }

    .externalMenu .navSubLink:last-of-type {
      border-right: none;
    }

    nav {
      background-color: #FFFFFF;
      display: block;
      text-align: right;
      padding: 0px;
    }

    .navLink {
      display: inline-block;
      position: relative;
      border-bottom: none;
    }

    .navLink a {
      display: block;
      font-weight: normal;
      padding: 15px 20px;
      font-size: 20px;
    }

    .navLink:hover {
      background-color: #002855;
    }

    .navLink:hover a {
      color: #FFFFFF;
    }

    .navLink .dropdown {
      display: none;
    }

    .navLink:hover .dropdown {
      display: block;
      position: absolute;
      top: 60px;
      left: 0px;
      text-align: left;
      width: 150%;
      background-color: #FFFFFF;
      border-top: 1px solid rgba(112, 115, 114, .25);
      box-shadow: 8px 8px 15px rgba(0, 0, 0, .16);
    }

    .navLink:hover .dropdown .navSubLink {
      display: block;
      height: auto;
    }

    .navLink:hover .navSubLink:not(:last-of-type) {
      border-bottom: 1px solid rgba(112, 115, 114, .25);
    }

    .navLink:hover .dropdown .navSubLink:hover {
      background-color: rgba(0, 76, 151, .05);
    }

    .navLink:hover .dropdown .navSubLink {
      font-size: 18px;
      margin: 0px;
      padding: 20px;
      color: #000000;
    }
    
  }

}